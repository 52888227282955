// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-autor-detail-js": () => import("./../src/components/autor_detail.js" /* webpackChunkName: "component---src-components-autor-detail-js" */),
  "component---src-components-book-detail-js": () => import("./../src/components/book_detail.js" /* webpackChunkName: "component---src-components-book-detail-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-js": () => import("./../src/pages/acerca.js" /* webpackChunkName: "component---src-pages-acerca-js" */),
  "component---src-pages-autores-js": () => import("./../src/pages/autores.js" /* webpackChunkName: "component---src-pages-autores-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-fake-book-detail-js": () => import("./../src/pages/fake_book_detail.js" /* webpackChunkName: "component---src-pages-fake-book-detail-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-libros-js": () => import("./../src/pages/libros.js" /* webpackChunkName: "component---src-pages-libros-js" */),
  "component---src-pages-noticias-js": () => import("./../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-puntos-de-venta-js": () => import("./../src/pages/puntos_de_venta.js" /* webpackChunkName: "component---src-pages-puntos-de-venta-js" */),
  "component---src-pages-recepcion-de-manuscritos-js": () => import("./../src/pages/recepcion_de_manuscritos.js" /* webpackChunkName: "component---src-pages-recepcion-de-manuscritos-js" */),
  "component---src-pages-servicios-editoriales-js": () => import("./../src/pages/servicios_editoriales.js" /* webpackChunkName: "component---src-pages-servicios-editoriales-js" */)
}

